
export default function AngleBoxGradient(props) {
    return (
        <div className="relative ">
            <div className="skew-top"></div>
            <div className="relative bg-gradient-trust">
                <div className="container py-32">
                    {props.children}
                </div>
            </div>
            <div className="skew-bottom"></div>
        </div>
    )
}