import Section from './section';
import GetMedia from '../lib/media';
import Link from 'next/link';
import Image from 'next/image';
export default function ContentBoxList(props) {
    return (
        <Section className={`z-10 relative ${props.sectionCss ? props.sectionCss : ''}`}>
            {props.style === 'columns' && (
                <div
                    className={`flex  flex-col lg:flex-row gap-4 px-4 xl:px-0 ${props.listCss ?? props.listCss} items-${props.boxes.length}`}
                >
                    {props.boxes.map((entry) => (
                        <div
                            key={`box-entry-${entry.id.toString()}`}
                            className="content-box rounded-[0.25rem] shadow-lg border-[1px] border-maturity bg-white overflow-hidden"
                        >
                            {entry.photo.data && (
                                <div className="content-box-photo h-[22rem] relative z-30 text-center flex items-center justify-center box-image">
                                    <Image
                                        src={GetMedia(entry.photo)}
                                        objectFit="cover"
                                        alt={entry.photo.data.attributes.alternativeText || ''}
                                        quality={80}
                                        layout="fill"
                                        className="object-fill"
                                    ></Image>
                                </div>
                            )}
                            <div className="content-box-content block p-10 box-content">
                                {entry.title && (
                                    <strong className="text-action block uppercase">{entry.title}</strong>
                                )}
                                <span
                                    className={`block ${props.textCss ?? props.textCss}`}
                                    dangerouslySetInnerHTML={{ __html: entry.content }}
                                ></span>
                                {entry.buttons && entry.buttons.map((button) => (
                                    <Link key={`content-box-button-${button.id}`} href={button.link}>
                                        <a title={button.label} className={`btn mt-4  ${button.style}`}>
                                            {button.label}
                                        </a>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {props.style === 'zig-zag-list' && (
                <div className={`flex flex-col gap-4 px-10 xl:px-0 ${props.listCss ?? props.listCss}`}>
                    {props.boxes.map((entry, index) => (
                        <div
                            key={entry.id.toString()}
                            className="content-box-zig-zag flex flex-col lg:flex-row justify-start items-start"
                        >
                            <div
                                className={`relative content-box-image z-30 flex rounded-lg  justify-start ${index % 2 == 0 ? 'lg:order-0' : 'lg:order-2'
                                    }`}
                            >
                                {entry.photo.data && (
                                    <Image
                                        src={GetMedia(entry.photo)}
                                        alt={entry.photo.data.attributes.alternativeText || ''}
                                        quality={80}
                                        width={entry.photo.data.attributes.width}
                                        height={entry.photo.data.attributes.height}
                                        objectFit="contain"
                                        className="rounded-lg overflow-hidden"
                                    ></Image>
                                )}
                            </div>
                            <div className="content-box-content flex flex-col basis-1 md:basis-2/3 lg:basis-1/2 pt-4 lg:pt-0 lg:px-10 mb-20">
                                <h2 className="text-trust block uppercase">{entry.title}</h2>
                                <span
                                    className={`block ${props.textCss ?? props.textCss}`}
                                    dangerouslySetInnerHTML={{ __html: entry.content }}
                                ></span>
                                {entry.buttons && entry.buttons.map((button) => (
                                    <Link key={`content-box-button-${button.id}`} href={button.link}>
                                        <a title={button.label} className={`btn mt-4 btn-lg ${button.style}`}>
                                            {button.label}
                                        </a>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </Section>
    );
}
