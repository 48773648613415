
export default function AngleBox(props) {
    return (
        <div className="relative bg-maturity-100 angle-box">
            <div className="skew-top-full"></div>
            <div className="skew-top "></div>
            <div className="container mt-4">
                {props.children}
            </div>
            <div className="skew-bottom-border"></div>
            <div className="skew-bottom"></div>
        </div>
    )
}