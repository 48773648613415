import Section from './section';
import GetMedia from '../lib/media';
import Image from 'next/image';

export default function TestimonialList(props) {
    return (
        <Section className={`z-10 py-8 relative testimonials ${props.style ? props.style : ''}`}>
            {props.headerContent &&
                <div
                    className="testimonials-title" dangerouslySetInnerHTML={{ __html: props.headerContent }}>
                </div>}
            <div
                className={`flex flex-wrap flex-row w-full lg:flex-row p-4 xl:px-0`}
            >
                {props.testimonials.data.map((entry) => (
                    <div
                        key={`testimonial-${entry.id}`}
                        className="flex p-4 w-full md:w-1/2 lg:w-1/3"
                    >
                        <div className="flex flex-col rounded-[0.25rem] bg-white p-8 py-16 shadow-sm">
                            <div className="w-2/3 max-w-[10rem] aspect-square relative z-30 text-center flex items-center justify-center rounded-full overflow-hidden mx-auto ">
                                {entry.attributes.photo && (
                                    <Image
                                        src={GetMedia(entry.attributes.photo)}
                                        objectFit="cover"
                                        alt={entry.attributes.photo.data.attributes.alternativeText || ''}
                                        quality={80}
                                        layout="fill"
                                        className="object-fill"
                                    ></Image>
                                )}
                            </div>
                            <div className="block p-4 box-content text-center">
                                <strong className="text-black block ">{entry.attributes.author}</strong>
                                {entry.attributes.job_title && <span className="text-black block ">{entry.attributes.job_title}</span>}
                                <span
                                    className="block"
                                    dangerouslySetInnerHTML={{ __html: entry.attributes.content }}
                                ></span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </Section>
    );
}
