import AngleBox from './angle-box';
import Image from 'next/image';
import Link from 'next/link';
import GetMedia from '../lib/media';
import AngleBoxGradient from './angle-box-gradient';

export default function LargeBox(props) {

    return (
        <section key={`section-${props.id}`} className={`${props.style} py-8`}>
            {(props.style === 'white-text-left' || props.style === 'white-text-right') && (
                <div key={`large-b-${props.id}`} className="white-box">
                    <div className={`w-full lg:w-1/2 flex flex-col p-8 justify-center items-start ${props.style === 'white-text-right' ? 'order-2' : ''}`}>
                        <span dangerouslySetInnerHTML={{
                            __html: props.content
                        }}></span>
                        {props.buttons && props.buttons.map((button, index) => (
                            <>
                                <Link key={`lb-button-${props.id}-${button.id}-${index}`}
                                    href={button.link}
                                >
                                    <a title={button.label} className={`btn mt-4 btn-lg ${button.style}`}>
                                        {button.label}
                                    </a>
                                </Link>
                            </>
                        ))}
                    </div>

                    <div className="relative flex w-full lg:w-1/2 align-center photo-wrapper justify-center items-center h-96 lg:h-auto overflow-hidden">
                        <Image

                            width={props.photo.data.attributes.width}
                            height={props.photo.data.attributes.height}
                            objectFit={'cover'}
                            src={GetMedia(props.photo)}
                            alt={props.photo.data.attributes.alternativeText || ''}
                            quality={85}

                        ></Image>
                    </div>
                </div>
            )}
            {props.style === 'dark-blue' && (
                <div key={`large-b-${props.id}`} className="dark-blue-box">
                    <div className="w-full lg:w-1/2 flex flex-col p-8 justify-center items-start">
                        <span dangerouslySetInnerHTML={{
                            __html: props.content
                        }}></span>
                        {props.buttons && props.buttons.map((button) => (
                            <Link key={`lb-button-${props.id}-${button.id}`}
                                href={button.link}
                            >
                                <a
                                    title={button.label}
                                    className={`btn mt-4 btn-lg ${button.style}`}
                                >
                                    {button.label}
                                </a>
                            </Link>
                        ))}
                    </div>

                    <div className="relative flex w-full lg:w-1/2 align-center photo-wrapper justify-center items-center h-96 lg:h-auto overflow-hidden">
                        <Image

                            width={props.photo.data.attributes.width}
                            height={props.photo.data.attributes.height}
                            objectFit={'cover'}
                            src={GetMedia(props.photo)}
                            alt={props.photo.data.attributes.alternativeText || ''}
                            quality={85}

                        ></Image>
                    </div>
                </div>
            )}

            {props.style === 'angled-blue' && (
                <AngleBoxGradient key={`large-b-${props.id}`} >
                    <div className="flex flex-col lg:flex-row angle-box-gradient">
                        <div className="relative flex w-full lg:w-3/5 align-center photo-wrapper justify-center items-center">

                            <Image

                                width={props.photo.data.attributes.width}
                                height={props.photo.data.attributes.height}
                                objectFit={'cover'}
                                src={GetMedia(props.photo)}
                                alt={props.photo.data.attributes.alternativeText || ''}
                                quality={85}

                            ></Image>


                        </div>

                        <div className="w-full lg:w-2/5 flex flex-col mb-[4rem] py-8 px-8 text-left justify-center items-start z-2 lg:py-32 lg:mb-0 lg:pr-0 ">
                            <span dangerouslySetInnerHTML={{
                                __html: props.content
                            }}></span>
                            {props.buttons && props.buttons.map((button) => (
                                <Link key={`lb-button-${props.id}-${button.id}`}
                                    href={button.link}
                                >
                                    <a
                                        title={button.label}
                                        className={`btn mt-4 btn-lg ${button.style}`}
                                    >
                                        {button.label}
                                    </a>
                                </Link>
                            ))}

                        </div>
                    </div>
                </AngleBoxGradient>
            )}

            {props.style === 'angled-gray' && (
                <AngleBox key={`large-b-${props.id}`} >

                    <div className="flex flex-col lg:flex-row">
                        <div className="relative  bg-maturity-100 flex w-full lg:w-1/2 align-center photo-wrapper justify-center items-center">
                            <Image
                                width={props.photo.data.attributes.width}
                                height={props.photo.data.attributes.height}
                                objectFit={'cover'}
                                src={GetMedia(props.photo)}
                                alt={props.photo.data.attributes.alternativeText || ''}
                                quality={85}
                            ></Image>

                            <div className="skew-bottom maturity-100 lg:hidden"></div>
                        </div>

                        <div className="w-full lg:w-1/2 flex flex-col mb-[10rem] py-8 px-8 text-left justify-center items-start bg-maturity-100 z-2 lg:py-32 lg:mb-0 lg:pr-0 ">
                            <span dangerouslySetInnerHTML={{
                                __html: props.content
                            }}></span>
                            {props.buttons && props.buttons.map((button) => (
                                <Link key={`lb-button-${props.id}-${button.id}`}
                                    href={button.link}
                                >
                                    <a
                                        title={button.label}
                                        className={`btn mt-4 btn-lg ${button.style}`}
                                    >
                                        {button.label}
                                    </a>
                                </Link>
                            ))}

                        </div>
                    </div>

                </AngleBox>
            )}

        </section>
    );
}
