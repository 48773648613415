export default function Title(props) {

    const getAlignClass = (align) => {
        switch (align) {
            case 'center':
                return 'justify-center text-center';
                break;
            case 'right':
                return 'justify-end text-right';
                break
            default:
                return 'justify-start';
                break;
        }
    }

    const align = getAlignClass(props.align);
    return (

        <div className={`flex flex-col w-full max-w-screen-xl mx-auto title-box ${align} ${props.css ? props.css : ''}`}>
            <div className="flex flex-row items-center px-4">
                <span className="w-full"
                    dangerouslySetInnerHTML={{ __html: props.title }}
                ></span>
            </div>
        </div>
    );
}
